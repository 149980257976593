import React from "react";
import data from "../../data.json";
const Blogs = () => {
  const blogItems = data?.blogItems;
  return (
      <div className="flex max-lg:flex-col 2xl:px-64 xl:px-32 sm:px-16 max-sm:px-8 gap-6 py-20">
        <div className="lg:w-2/3">
          <div className="flex flex-col gap-5">
            {blogItems?.map((item) => (
              <div className="blog-card ">
              <img
                src={item?.image}
                alt=""
                className="rounded-t-lg w-full mb-5"
              />
              <span className="text-[#0D0E10]">{item?.createdAt}</span>
              <h1 className="text-2xl font-bold mt-5">{item?.title}</h1>
              <p>{item?.subtitle}</p>
              <div className="bg-[#0E38B1] text-white py-3 px-10 rounded-lg group cursor-pointer w-52 mt-5 ">
                <a href=""><div className="flex">
                  <p>Devamı İçin </p>
                  <span className="group-hover:ps-2 transition-all">
                    <i class="ri-arrow-right-line" />
                  </span>
                </div></a>
              </div>
            </div>
            ))}
          </div>
        </div>
        <div className="lg:w-1/3">
          {/* SEARCH-AREA */}
          <div className="">
            <h1 className="text-2xl font-bold">Bir Kelime Ara</h1>
            <input
              type="text"
              name="search"
              placeholder="Bir Kelime Ara"
              className="border border-blue-700 rounded-ss-lg rounded-ee-lg h-12 w-full ps-4"
            />
          </div>
          {/* SERVICES-AREA */}
          <div className="mt-10">
            <h1 className="text-2xl font-bold mb-5">SERVİSLERİMİZ</h1>
            <div className="flex flex-col gap-2 text-[#0D0E10]">
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
            </div>
          </div>
          {/* SON EKLENEN BLOGLAR */}
          <div className="mt-10">
            <h1 className="text-2xl font-bold mb-5">SON EKLENEN BLOGLAR</h1>
            <div className="flex flex-col gap-4">
              {blogItems?.map((item) => (
                <div className="flex gap-2">
                <img src={item?.image} alt="" className="w-28 h-28 object-cover rounded-md" />
                <div className="flex flex-col justify-between">
                    <span>{item?.createdAt}</span>
                    <h1 className="line-clamp-1 font-bold">{item?.title}</h1>
                    <h1 className="line-clamp-2">{item?.subtitle}</h1>
                </div>
            </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Blogs;
