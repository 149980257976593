import React from "react";

const Brands = () => {
  const data = [
    {
      img: "/images/navision-logo.png",
      title: "Navision      ",
      url: "/yakinda-sizlerle",
      text: "ReactJS, modern kullanıcı arayüzleri geliştirmek için kullanılan güçlü ve esnek bir JavaScript kütüphanesidir. Bileşen tabanlı yapısı sayesinde kodunuzu modüler ve tekrar kullanılabilir hale getirirken, sanal DOM özelliği ile yüksek performans sağlar. ReactJS, geliştiricilere kullanıcı deneyimini üst düzeye çıkaracak dinamik ve interaktif web uygulamaları oluşturma imkanı sunar.",
    },
    {
      img: "/images/ellusion-create-logo.png",
      title: "ellusion Creative",
      url: "https://",
      text: "Node.js, JavaScript’i sunucu tarafında çalıştırmayı mümkün kılan güçlü ve hızlı bir platformdur. Olay tabanlı ve asenkron yapısı sayesinde yüksek performans gerektiren uygulamalar için idealdir. Node.js, geniş ekosistemi ve NPM desteği ile geliştiricilere ölçeklenebilir, hızlı ve güvenilir sunucu uygulamaları oluşturma imkanı sunar.",
    },
    {
      img: "/images/ellu-agency-logo.png",
      title: "PostAI",
      url: "/yakinda-sizlerle",
      text: "MongoDB, modern uygulamalar için tasarlanmış, belge tabanlı bir NoSQL veritabanıdır. Esnek veri yapısı, hiyerarşik ilişkileri ve karmaşık veri türlerini kolayca yönetmenize olanak tanır. Yüksek performansı ve ölçeklenebilirliği ile MongoDB, geliştiricilere güvenilir ve esnek veri yönetimi sağlar.",
    },
    {
      img: "/images/agency-logo.png",
      title: "ellucation",
      url: "/yakinda-sizlerle",
      text: "MongoDB, modern uygulamalar için tasarlanmış, belge tabanlı bir NoSQL veritabanıdır. Esnek veri yapısı, hiyerarşik ilişkileri ve karmaşık veri türlerini kolayca yönetmenize olanak tanır. Yüksek performansı ve ölçeklenebilirliği ile MongoDB, geliştiricilere güvenilir ve esnek veri yönetimi sağlar.",
    },
  ];

  return (
    <div className="px-32 max-sm:px-10">
      <div className="text-center  mb-10 pt-10">
        <h1 className="text-4xl font-semibold">Markalar</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda molestias tenetur repellendus consequatur provident neque atque eum eius, tempore debitis eos culpa ducimus corrupti quas iusto incidunt. Deserunt earum neque aspernatur quos, vel error odit doloremque iste sed, incidunt hic.
        </p>
      </div>

      <div className="">
        {data?.map((item) => (
          <div className="grid grid-cols-3 my-10 rounded">
            <div className="max-sm:col-span-3 col-span-2">
              <div className="flex flex-col justify-center h-full max-sm:items-center">
                <h1 className="text-4xl font-bold">{item?.title}</h1>
                <p className="">{item?.text}</p>
                <a href={item?.url} className="bg-[#0E38B1] hover:bg-[#3553ad] transition-all text-white py-3 px-10 rounded-lg group cursor-pointer w-52 mt-5 ">Sayfaya Git</a>
              </div>
            </div>
            <div className="max-sm:col-span-3 col-span-1">
              <img src={item?.img} alt="" className="w-full min-h-72 max-h-72" />
            </div>
          </div>
        ))}
      </div>


    </div>
  );
};

export default Brands;
