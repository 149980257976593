import React, { useEffect } from "react";
import Slider from "react-slick";
import Reveal from "./Reveal";
import data from "../../data.json";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Blog3 = () => {
  const blogItems = data?.blogItems;

  const CustomArrow = ({ className, style, onClick, direction }) => {
    return (
      <div
        style={{
          ...style,
          display: "block",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          position: "absolute",
          top: "50%",
          zIndex: 1000,
          ...(direction === "left" ? { left: "0" } : { right: "0" }),
        }}
        onClick={onClick}
      >
        {direction === "left" ? (
          <div className="-mt-10 -ms-8 max-md:hidden">
            <div className="bg-[#E7EBF7] rounded-full w-10 h-10 flex justify-center items-center ">
              <i class="ri-arrow-left-line text-xl text-[#0E38B1]"></i>
            </div>
          </div>
        ) : (
          <div className="-mt-10 -me-10 max-md:hidden">
            <div className="bg-[#E7EBF7] rounded-full w-10 h-10 flex justify-center items-center ">
              <i class="ri-arrow-right-line text-xl text-[#0E38B1]"></i>
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    //GLOBAL FADE-TEXT STARTED HERE!!!!!!
    gsap.fromTo(
      ".fade-left-2",
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".fade-left-2",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".fade-right-2",
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".fade-right-2",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    prevArrow: <CustomArrow direction="left" />,
    nextArrow: <CustomArrow direction="right" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="flex flex-col w-full items-center mb-10">
        <span className="flex items-center gap-1 text-[#0E38B1] fade-left-2 font-bold">
          <img
            src="/images/span1.png"
            alt="circle"
            className="h-[16px] w-[18px]"
          />{" "}
          Bloglar ve Son Haberler
        </span>
        <h1 className="text-4xl font-bold fade-right-2">Bloglarımız</h1>
      </div>
      <div className="slider-container pb-12 fade-left-2">
        <Slider {...settings}>
          {blogItems?.map((blog) => (
            <Reveal>
              <a href={`/blog/${blog?.slug}`} className="">
                <div className="ps-2 cursor-pointer">
                  <img
                    src={blog.image}
                    alt="blog_img"
                    className="rounded-ss-xl rounded-se-xl"
                  />
                  <div className="flex flex-col gap-4 mb-2">
                    <h1 className="font-bold text-3xl mt-4 text-black">
                      {blog?.title}
                    </h1>
                    <p className="blog-card-desc">{blog?.subtitle}</p>
                    <span>
                      <i className="ri-arrow-right-line text-2xl text-black hover:ms-2 hover:bg-black hover:text-white transition-all cursor-pointer border-black border px-5 py-2 rounded-full"></i>
                    </span>
                  </div>
                </div>
              </a>
            </Reveal>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Blog3;
