import React from 'react'

const Contact = () => {
  return (
    <div>
        <div className="">
            
        </div>
    </div>
  )
}

export default Contact