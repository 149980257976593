import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Reveal from "./components/layouts/Reveal";
import BlogDetails from "./components/pages/BlogDetails";
import { useEffect, useRef } from "react";
import Contact from "./components/pages/Contact";
import Blogs from "./components/pages/Blogs";
import OurTechonology from "./components/pages/OurTechonology";
import Brands from "./components/pages/Brands";
import ComingSoon from "./components/pages/ComingSoon";
import Login from "./components/pages/Login";
import { Toaster } from "react-hot-toast";

function App() {
  const footerRef = useRef();

  return (
    <Router>
      <div className="">
        <Header />
        <Toaster position="top-right" />
      </div>
      <div className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog/:slug" element={<BlogDetails footerRef={footerRef} />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/bloglar" element={<Blogs />} />
          <Route path="/teknoloji" element={<OurTechonology />} />
          <Route path="/markalar" element={<Brands />} />
          <Route path="/yakinda-sizlerle" element={<ComingSoon />} />
          <Route path="/giris" element={<Login />} />
        </Routes>
      </div>
      <Reveal>
        <Footer footerRef={footerRef} />
      </Reveal>
    </Router>
  );
}

export default App;
