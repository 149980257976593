import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderComp = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sliderItems =
    windowWidth < 768
      ? [
          { img: "/images/slider-img-mobile.jpeg" },
          { img: "/images/slider-img-mobile-2.jpeg" },
        ]
      : [
          { img: "/images/slider-img.jpg" },
          { img: "/images/slider-img-2.jpeg" },
        ];
  const settings = {
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  };
  return (
    <div className="slider-container w-full -mb-2 max-sm:max-h-[70vh] sm:max-h-screen h-full">
      <Slider {...settings} className="rounded-xl">
        {sliderItems?.map((img) => (
          <div className="">
            <img
              src={img.img}
              className="max-sm:max-h-[70vh] sm:max-h-screen h-full w-full"
              alt=""
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComp;
