import React from "react";

const BubbleSection = ({children}) => {
  const BubbleText = () => {
    return (
      <h2 className="text-center text-8xl max-sm:text-5xl font-thin text-indigo-300">
        {(children).toString().split("").map((child, idx) => (
          <span className="hoverText" key={idx}>
            {child}
          </span>
        ))}
      </h2>
    );
  };
  return (
    <div className="grid h-screen place-content-center px-5">
      <BubbleText />
    </div>
  );
};

export default BubbleSection;
