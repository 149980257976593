import React from "react";
import BubbleSection from "./BubbleSection";

const ParallaxContainer = () => {
  return (
    <div className="parallax">
      <div className="bg-black bg-opacity-90">
        <div className="content">
          <BubbleSection>Technology for Next Generations</BubbleSection>
        </div>
      </div>
    </div>
  );
};

export default ParallaxContainer;
