import React, { useEffect, useState } from "react";
import Reveal from "./Reveal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const CounterArea = () => {
  const [count, setCount] = useState(0);
  const stats = [
    {
      id: 1,
      value: "5",
      label: "Devam Eden Proje.",
      largeNumber: "01",
      className: "counter-left",
    },
    {
      id: 2,
      value: "60",
      label: "Tamamlanmış Başarılı Proje",
      largeNumber: "02",
      className: "counter-left",
    },
    {
      id: 3,
      value: "60",
      label: "Kullanıcı ve İş Birliği",
      largeNumber: "03",
      className: "counter-right",
    },
    {
      id: 4,
      value: "100",
      label: "Yaratıcı Çözümler ve İş Birlikleri",
      largeNumber: "04",
      className: "counter-right",
    },
  ];

  const Counter = ({ maxValue, id }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
     
      if (count < maxValue) {
        const interval = setInterval(() => {
          setCount((prevCount) => prevCount + 1);
        }, 10); // Her 10 milisaniyede artırır

        return () => clearInterval(interval);
      }
    }, [count, maxValue]);

    const data = id === 3 ? (<h1>{count}B+</h1>) : (<h1>{count}+</h1>);
    return data
  };

  useEffect(() => {
    gsap.fromTo(
      ".counter-left",
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: ".counter-left",
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".counter-right",
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: ".counter-right",
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, [])


  return (
    <div className="statistics-container md:h-96">
      {stats.map((stat) => (
        <Reveal>
          <div
            key={stat.id}
            className={`stat-item max-md:mt-16 ${stat.className}`}
          >
            <div className={`relative z-10 max-md:mb-16 `}>
              <div className="icon-container">
                <div className="cube"></div>
              </div>
              <div className="count-text">
                <Counter maxValue={stat.value} id={stat.id} />
                <p>{stat.label}</p>
              </div>
            </div>
            <div className="large-number">{stat.largeNumber}</div>
          </div>
        </Reveal>
      ))}
    </div>
  );
};

export default CounterArea;
