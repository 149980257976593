import React from "react";
import data from "../../data.json";
const Footer = ({ footerRef }) => {
  const blogItems = data?.blogItems;
  return (
    <>
      <footer className="flex justify-center items-center mt-10 w-full" ref={footerRef} >
        <div className="grid lg:grid-cols-4 max-lg:grid-cols-2 max-md:grid-cols-1 gap-10 max-sm:px-10 sm:w-3/4 xl:w-3/5">
          <div className="">
            <img src="/images/logo.png" alt="" />
            <p className="mt-5">Ellusion, yenilikçi projeler geliştirip büyütmek için teknoloji ve yaratıcılığı bir araya getirir. Sektördeki lider konumlarına ulaşmak için her fikri gerçeğe dönüştürür ve kalıcı bir etki bırakır.
            Gelecek, bizimle şekillenir.</p>
          </div>
          <div className="text-">
            <h1 className="text-2xl font-bold text-[#0D0E10]">Kullanışlı Linkler</h1>
            <div className="flex flex-col gap-5 ">
              <a href="/x" className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500 mt-3">Anasayfa</a>
              <a href="/bloglar" className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500">Bloglar</a>
              <a href="/markalar" className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500">Markalar</a>
            </div>
          </div>
          <div className="text-">
            <h1 className="text-2xl font-bold text-[#0D0E10]">Son Eklenen Bloglar</h1>
            <div className="flex flex-col gap-5">
              {blogItems?.slice(-4)?.map((item) => <a href={`/blog/${item?.slug}`} className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500 line-clamp-2">{item?.title}</a>)}
            </div>
          </div>
          <div className="text-">
            <h1 className="text-2xl font-bold text-[#0D0E10]">İletişim</h1>
            <div className="flex flex-col gap-5">
              <a href="mailto:info@ellusion.tech" className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500">info@ellusion.tech</a>
              <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj5qZ6X746KAxUWA9sEHQApFvIQ4kB6BAgqEAM&url=%2Fmaps%2Fplace%2F%2Fdata%3D!4m2!3m1!1s0x14caa37b7c105081%3A0x2fa6c647ba2e494b%3Fsa%3DX%26ved%3D1t%3A8290%26ictx%3D111&usg=AOvVaw3tLtUBq5rEjxSsDdaAVCfN&opi=89978449" className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500">Beşyol, İnönü Cd. No:38, 34295, 34000 Küçükçekmece/İstanbul</a>
            </div>
          </div>
        </div>
      </footer>

      <section className="border-t text-center mt-10 pt-5 pb-2 text-[#070707]">
        <p>Tüm Hakları Ellusion Şirketine Aittir.</p>
      </section>
    </>
  );
};

export default Footer;
