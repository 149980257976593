import React, { useEffect } from "react";
import Reveal from "./Reveal";
import { Example } from "./ClipPathIcons";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const OurLanguage = () => {
    useEffect(() => {
        //GLOBAL FADE-TEXT STARTED HERE!!!!!!
        gsap.fromTo(
          ".fade-left-3",
          {
            opacity: 0,
            x: 200,
          },
          {
            opacity: 1,
            x: 0,
            duration: 1,
            scrollTrigger: {
              trigger: ".fade-left-3",
              start: "top 80%",
              end: "bottom 30%",
              toggleActions: "play none none reverse",
            },
          }
        );
        gsap.fromTo(
          ".fade-right-3",
          {
            opacity: 0,
            x: -200,
          },
          {
            opacity: 1,
            x: 0,
            duration: 1,
            scrollTrigger: {
              trigger: ".fade-right-3",
              start: "top 80%",
              end: "bottom 30%",
              toggleActions: "play none none reverse",
            },
          }
        );
      }, []);
  return (
    <div className="max-sm:px-3 max-lg:px-10 ps-10 xl:ps-28 mt-16">
      <div className="mb-10 flex flex-col items-center text-center w-full">
        <span className="flex items-center gap-1 text-[#0E38B1] fade-left-3 ">
          <img
            src="/images/span1.png"
            alt="circle"
            className="h-[16px] w-[18px]"
          />{" "}
          Teknoloji
        </span>
        <h1 className="text-4xl font-bold mb-2 fade-right-3">
          Projelerimizde Kullandığımız Teknolojiler
        </h1>
        <p className="fade-left-3">
          Projelerimizi en yüksek kaliteye ulaştırmak ve müşterilerimize en iyi
          çözümleri sunmak için ileri teknoloji araçları ve yazılım dillerini
          kullanıyoruz. Bu bölümde, geliştirme süreçlerimizde tercih ettiğimiz
          teknoloji yığınını ve bu araçların sağladığı avantajları
          bulabilirsiniz. İhtiyaçlara en uygun ve verimli çözümleri üretebilmek
          adına sürekli olarak en güncel ve yenilikçi teknolojileri takip
          ediyor, projelerimizde uyguluyoruz.
        </p>
      </div>
      <Reveal>
        <Example />
      </Reveal>
    </div>
  );
};

export default OurLanguage;
