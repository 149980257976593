import React, { useEffect, useState } from "react";
import { FaArrowTurnDown } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";

const Header = () => {
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState({
    hover: false,
    number: 0,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    hover: false,
    number: 0,
    hidden: true,
  });
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [activeUrl, setActiveUrl] = useState("");

  const navbarItems = [
    {
      url: "/",
      text: "KURUMSAL",
      dropdown: true,
      dropdownNumber: 0,
      dropdownItems: [
        {
          url: "/",
          text: "KURUMSAL 1",
        },
        {
          url: "/",
          text: "KURUMSAL 2",
        },
        {
          url: "/",
          text: "KURUMSAL 3",
        },
      ],
    },
    {
      url: "/markalar",
      text: "MARKALAR",
      dropdown: false,
    },
    {
      url: "/yatirimci",
      text: "YATIRIMCI İLİŞKİLERİ",
      dropdown: true,
      dropdownNumber: 1,
      dropdownItems: [
        {
          url: "/giris?q=navision",
          text: "navision",
        },
        {
          url: "/giris?q=postai",
          text: "PostAI",
        },
        {
          url: "/giris?q=ellucation",
          text: "ellucation",
        },
      ],
    },
    {
      url: "/yakinda-sizlerle",
      text: "TEKNOLOJİ",
      dropdown: false,
    },
    {
      url: "/surdurebilirlik",
      text: "SÜRDÜRÜLEBİLİRLİK",
      dropdown: true,
      dropdownNumber: 2,
      dropdownItems: [
        {
          url: "/giris?q=navision",
          text: "navision",
        },
        {
          url: "/giris?q=postai",
          text: "PostAI",
        },
        {
          url: "/giris?q=ellucation",
          text: "ellucation",
        },
      ],
    },
    {
      url: "/medya",
      text: "MEDYA MERKEZİ",
      dropdown: true,
      dropdownNumber: 3,
      dropdownItems: [
        {
          url: "/bloglar",
          text: "Bloglar",
        },
      ],
    },
  ];

  useEffect(() => {
    setActiveUrl(window.location.pathname);
  }, []);

  return (
    <div className="mb-[112px]">
      <nav onMouseLeave={() => setIsMegaMenuOpen(false)} className={`h-28 flex items-center w-full z-30 max-lg:px-10 lg:px-3 xl:px-32 shadow ${ openHamburgerMenu && "border-b"} bg-white fixed transition-all top-0 `} >
        <div className="w-full flex justify-between items-center gap-5">
          <div className="text-2xl font-bold">
            <a href="/"><img src="/images/logo.png" className="max-sm:h-10 h-12" alt="logo-png" /></a>
          </div>

          <ul className="flex max-lg:hidden 2xl:pe-32 2xl:gap-10 xl:gap-6 max-xl:gap-4" style={{ fontSize: "13px" }}>
            {navbarItems?.map((item) =>
              item.dropdown ? (
                <>
                  <li className="relative group text-white">
                    <a style={{ transition: "all .4s ease" }} className={`hover:font-bold text-black ${item.url === activeUrl && "font-bold" }`}
                      onMouseMove={() => setIsMegaMenuOpen({ hover: true, number: item.dropdownNumber})}>
                      {item.text}
                    </a>
                    {/* Dropdown Menu STARTED */}
                    <ul
                      className={`absolute left-0 ${isMegaMenuOpen.hover &&isMegaMenuOpen.number === item.dropdownNumber? "block": "hidden"} bg-white text-black mt-2 py-3 rounded shadow-lg z-10 min-w-[300px]`}
                      onMouseLeave={() => setIsMegaMenuOpen({ hover: false, number: 0 })}>
                      {item?.dropdownItems?.map((dropItem) => (
                        <li className="px-8 py-3 ">
                          <a href={dropItem.url} className="hover:text-blue-500 hover:ms-2 transition-all text-black" style={{ transition: "all .4s ease" }}>{dropItem.text}</a>
                        </li>
                      ))}
                    </ul>
                    {/* Dropdown Menu FINISHED */}
                  </li>
                </>
              ) : (
                <>
                  <li
                    onMouseEnter={() =>
                      setIsMegaMenuOpen({ hover: false, number: 0 })
                    }
                  >
                    <a
                      href={item.url}
                      className="hover:font-bold text-black"
                      style={{ transition: "all .5s ease" }}
                    >
                      {item.text}
                    </a>
                  </li>
                </>
              )
            )}
          </ul>

          <div className="lg:hidden relative">
            <RxHamburgerMenu size={32} onClick={() => setOpenHamburgerMenu(!openHamburgerMenu)} className="bg-black text-white p-1 cursor-pointer" />
          </div>
        </div>

        <div className={`absolute z-50 bg-white lg:hidden left-0 min-h-[500px] transition-all w-full -top-[500px] ${ openHamburgerMenu && "top-28"}`}>
          <div className={`flex-col pe-10 ${openHamburgerMenu ? "flex": "hidden"}`}>
            {navbarItems?.map((item) =>
              item?.dropdown ? (
                <>
                  <div className="flex justify-between items-center" onClick={() => setIsDropdownOpen({ hover: !isDropdownOpen.hover, number: item.dropdownNumber, hidden: !isDropdownOpen.hidden})}>
                    <a className={`ps-10 py-3 text-2xl cursor-pointer hover:font-bold transition-colors ${ isDropdownOpen.hover && isDropdownOpen.number === item.dropdownNumber && "text-black" }`}>
                      {item.text}
                    </a>
                    <FaArrowTurnDown
                      color={`${ isDropdownOpen.hover && isDropdownOpen.number === item.dropdownNumber ? "black" : "gray" }`}
                      className="cursor-pointer" />
                  </div>
                  <div
                    className={`flex flex-col transition-all ${ isDropdownOpen.hover && isDropdownOpen.number === item.dropdownNumber ? "h-40" : "h-0 " } `}>
                    {item.dropdownItems?.map((dItem) => (
                      <a href={dItem.url} className={`ps-14 py-3 text-lg hover:font-bold ${ !isDropdownOpen.hidden && isDropdownOpen.hover && isDropdownOpen.number === item.dropdownNumber ? "" : "hidden" }`}>
                        {dItem.text}
                      </a>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <a
                    href={item.url}
                    className="ps-10 py-3 text-2xl hover:font-bold "
                  >
                    {item.text}
                  </a>
                </>
              )
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
