import React from 'react'
import { FaTools } from 'react-icons/fa'

const ComingSoon = () => {
  return (
    <div className='h-[80vh] flex justify-center items-center flex-col px-32 max-sm:px-10'>
        <h1 className='text-4xl font-bold mb-2'>Bakım Aşamasında</h1>
        <p>Şu anda bu sayfa üzerinde yoğun bir şekilde çalışmalarımız devam etmektedir. En iyi kullanıcı deneyimini sağlamak ve sizlere daha kaliteli bir hizmet sunabilmek adına özenle hazırlıyoruz. En kısa sürede sayfamız hizmetinize açılacaktır. Gösterdiğiniz sabır ve anlayış için teşekkür ederiz.</p>
        <div className="border rounded-full p-10 mt-10">
            <FaTools size={64} color='gray' />
        </div>
    </div>
  )
}

export default ComingSoon