import React, { useEffect, useRef } from "react";
import Reveal from "./Reveal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Section = () => {
  const elementOneRef = useRef(null);
  const elementTwoRef = useRef(null);
  const elementThreeRef = useRef(null);
  const elementFourRef = useRef(null);
  const itemList = [
    {
      title: "Navision",
      img: "/images/navision-logo.png",
      desc: "Navision, konum bazlı bir sosyal medya uygulamasıdır. Kullanıcılar, dünya genelindeki farklı yerleri keşfederken, arkadaşlarının konumlarını görüntüleyebilir ve etkileşimde bulunabilir. Bu uygulama, keşif ve bağlantı kurma deneyimini daha kişisel ve interaktif hale getirerek, sosyal medya kullanımını yeniden şekillendiriyor.",
    },
    {
      title: "Ellusion Creative",
      img: "/images/ellusion-create-logo.png",
      desc: "Ellusion Creative, markaların dijital varlıklarını güçlendiren yaratıcı bir ajans olarak, 2D ve 3D tasarımlar, sosyal medya yönetimi ve reklam kampanyalarıyla şirketlerin çevrimiçi görünürlüğünü artırır. Yaratıcı çözümlerle markaların hedef kitlesiyle güçlü bir bağ kurmasını sağlar, dijital dünyada kalıcı bir etki yaratır. Teknoloji ve estetiği harmanlayarak, markaların dijital alanda öne çıkmalarını sağlarız.",
    },
    {
      title: "Ellucation",
      img: "/images/ellu-agency-logo.png",
      desc: "Ellucation, yapay zeka destekli bir eğitim platformudur. Öğrencilerin derslere verdikleri yanıtlar üzerinden güçlü ve zayıf yönlerini analiz ederek, hangi konularda eksik kaldıklarını ve nerelere odaklanmaları gerektiğini belirler. Kişiselleştirilmiş öğrenme deneyimi sunarak, her öğrencinin başarıyı daha verimli bir şekilde yakalamasını sağlar. Eğitimde teknoloji ve veri analizi ile geleceği şekillendirir.",
    },
    {
      title: "PostAI",
      img: "/images/agency-logo.png",
      desc: "PostAI, yapay zeka teknolojisiyle sosyal medya yönetimini ve içerik tasarımını bir adım öteye taşır. Otomatik analizler ile sosyal medya performansını değerlendirir, trendleri takip eder ve hedef kitleye yönelik etkili post ve hikayeler (story) tasarımları oluşturur. Hem içerik üretimini hızlandırır hem de markaların dijital varlıklarını güçlendirmelerine yardımcı olur, sosyal medyada daha etkili ve verimli bir varlık oluşturur.",
    },
  ];

  useEffect(() => {
    gsap.fromTo(
      ".section-text-left2",
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: ".section-text-left2",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".section-text-right2",
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: ".section-text-right2",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      elementOneRef.current,
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: elementOneRef.current,
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      elementTwoRef.current,
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: elementTwoRef.current,
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      elementThreeRef.current,
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: elementThreeRef.current,
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      elementFourRef.current,
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: elementFourRef.current,
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="mt-20 bg-[#F1F4F6] py-28 relative overflow-hidden">
      <div className="absolute xl:-bottom-[70%] xl:left-[23%] lg:-bottom-[60%] lg:left-[5%] max-md:hidden">
        <img src="images/feat_circle.png" className="infiniteRotate" />
      </div>
      <div className="text-center">
        <div className="flex gap-2 items-center justify-center section-text-left2">
          <img src="/images/span1.png" alt="" className="h-[16px] w-[18px]" />
          <p className="text-[#0E38B1] font-bold">Her Fikir, Yeni Bir Başlangıçtır.</p>
        </div>
        <h1 className="text-4xl font-bold section-text-right2">Projelerimiz: Geleceği Şekillendiren İnovasyonlar</h1>
      </div>
      <section className="mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 max-lg:px-8 max-lg:gap-8 px-16 gap-20">
          <div className="" ref={elementOneRef}>
            <div className="flex items-center justify-center gap-4">
              <img src={itemList[0].img} alt="" className="h-24 w-24" />
              <h1 className="max-md:text-2xl text-3xl lg:text-4xl font-bold">
                {itemList[0].title}
              </h1>
            </div>
            <div className="">
              <p>{itemList[0].desc}</p>
            </div>
          </div>
          <Reveal>
            <div className="" ref={elementTwoRef}>
              <div className="flex items-center justify-center gap-4">
                <h1 className="max-md:text-2xl text-3xl lg:text-4xl font-bold text-end">
                  {itemList[1].title}
                </h1>
                <img src={itemList[1].img} alt="" className="h-24 w-24" />
              </div>
              <div className="text-center">
                <p>{itemList[1].desc}</p>
              </div>
            </div>
          </Reveal>
          <Reveal>
            <div className="" ref={elementThreeRef}>
              <div className="flex items-center justify-center gap-4">
                <img src={itemList[2].img} alt="" className="h-24 w-24" />
                <h1 className="max-md:text-2xl text-3xl lg:text-4xl font-bold">
                  {itemList[2].title}
                </h1>
              </div>
              <div className="text-center">
                <p>{itemList[2].desc}</p>
              </div>
            </div>
          </Reveal>
          <Reveal>
            <div className="" ref={elementFourRef}>
              <div className="flex items-center justify-center gap-4">
                <h1 className="max-md:text-2xl text-3xl lg:text-4xl font-bold text-end">
                  {itemList[3].title}
                </h1>
                <img src={itemList[3].img} alt="" className="h-24 w-24" />
              </div>
              <div className="text-center">
                <p>{itemList[3].desc}</p>
              </div>
            </div>
          </Reveal>
        </div>
      </section>
    </div>
  );
};

export default Section;
