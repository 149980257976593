import React, { useEffect, useRef } from "react";
import SliderComp from "../layouts/Slider";
import Blog3 from "../layouts/Blog3";
import ParallaxContainer from "../layouts/ParallaxContainer";
import ServicesArea from "../layouts/ServicesArea";
import CounterArea from "../layouts/CounterArea";
import Section from "../layouts/Section";
import Reveal from "../layouts/Reveal";
import Section2 from "../layouts/Section2";
import OurLanguage from "../layouts/OurLanguage";

const Home = ({ textLeave, textEnter }) => {
  return (
    <>
      <div className="overflow-hidden">
        <SliderComp />
      </div>
      <div className="font-noto overflow-hidden">
        <ServicesArea />

        <Section2 />
        <Section />

        <div className="">
          <Reveal>
            <ParallaxContainer />
          </Reveal>
          <Reveal>
            <CounterArea />
          </Reveal>
        </div>

        {/* BLOG-SECTION STARTED */}
        {/* <div className="max-sm:px-3 max-lg:px-10 ps-10 xl:ps-28 mt-16">
          <div className="" onMouseEnter={textEnter} onMouseLeave={textLeave}>
            <Reveal>
              <h1 className="font-bold text-5xl">
                Lorem ipsum dolor sit amet.
              </h1>
            </Reveal>{" "}
            <Reveal>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum
                quod esse perspiciatis fuga quaerat! Velit commodi neque
                voluptatem exercitationem consequatur doloribus facilis
                asperiores provident et, omnis doloremque? Animi, necessitatibus
                dolores!
              </p>
            </Reveal>
          </div>
          <Reveal>
            <BusinessSteps />
          </Reveal>
        </div> */}
        {/* BLOG-SECTION FINISHED */}

        <OurLanguage />

        <div className="max-sm:px-3 max-lg:px-10 px-28 mt-16">
          <Reveal>
            <Blog3 />
          </Reveal>
        </div>

        {/* SIKÇA SORULANLAR */}
        {/* <div className="max-sm:px-3 max-lg:px-10 ps-10 xl:ps-28 mt-16">
          <Reveal>
            <FAQ />
          </Reveal>
        </div> */}
      </div>
    </>
  );
};

export default Home;
