import React, { useEffect, useRef } from "react";
import Reveal from "./Reveal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Section2 = () => {
  const elementRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      elementRef.current,
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: elementRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      videoRef.current,
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: videoRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    
  }, []);
  return (
    <div className="max-sm:px-3 max-lg:px-10 ps-10 xl:ps-28 mt-16">
      <div className="max-lg:flex-col flex items-center justify-center gap-20">
        <div className="flex-grow">
          <div
            className="mt-16 lg:min-w-[500px] lg:max-w-[500px] animated-box"
            ref={elementRef}
          >
            <div className="flex items-center gap-1 fade-text-right">
              <img
                src="/images/span1.png"
                alt=""
                className="h-[16px] w-[18px]"
              />
              <p className="text-[#0E38B1] font-bold">HAKKIMIZDA</p>
            </div>
            <h1 className="text-4xl font-bold mb-5 fade-text-right">
            Geleceği Şekillendiren Startup Stüdyosu
            </h1>
            <p className="gray-p mb-5 fade-text-right">
            Ellusion, yenilikçi projeler geliştiren ve büyüten bir startup stüdyosudur. Teknolojiyi yaratıcı fikirlerle birleştirerek, farklı alanlarda değer yaratan çözümler sunar.
            <br/>
            <br/>
            Portföyümüzdeki projeler:
            </p>
            <div className="flex flex-col gap-4">
              <div className="flex gap-2 items-center fade-text-right">
                <span className="bg-[#0E38B1] rounded-full text-white w-[20px] h-[20px] flex items-center justify-center">
                  <i class="ri-check-line"></i>
                </span>
                <p>Navision: Konum bazlı sosyal medya uygulaması, kullanıcıların bağlantı kurma ve keşfetme deneyimini yeniden tanımlıyor.</p>
              </div>
              <div className="flex gap-2 items-center fade-text-right">
                <span className="bg-[#0E38B1] rounded-full text-white w-[20px] h-[20px] flex items-center justify-center">
                  <i class="ri-check-line"></i>
                </span>
                <p>
                Ellucation: Yapay zeka destekli eğitim platformu, öğrencilerin güçlü ve zayıf yönlerini belirleyerek kişiselleştirilmiş öğrenme sağlıyor.
                </p>
              </div>
              <div className="flex gap-2 items-center fade-text-right">
                <span className="bg-[#0E38B1] rounded-full text-white w-[20px] h-[20px] flex items-center justify-center">
                  <i class="ri-check-line"></i>
                </span>
                <p>Ellusion Creative: Markaların dijital varlıklarını güçlendiren yaratıcı bir ajans; 2D/3D tasarımlar, sosyal medya yönetimi ve reklam kampanyaları sunuyor.</p>
              </div>
              <div className="flex gap-2 items-center fade-text-right">
                <span className="bg-[#0E38B1] rounded-full text-white w-[20px] h-[20px] flex items-center justify-center">
                  <i class="ri-check-line"></i>
                </span>
                <p>PostAI: Yapay zeka ile sosyal medya yönetimi, performans analizi ve yaratıcı post ile hikaye tasarımı yapıyor.</p>
              </div>
              <div className="flex gap-2 items-center fade-text-right gray-p">
                <p>Ellusion, her fikri değerli bir girişime dönüştürerek geleceği bugünden inşa ediyor.</p>
              </div>
            </div>
          </div>
        </div>
        <Reveal>
          <div className="animated-box" ref={videoRef}>
            <video
              className="max-lg:w-full lg:w[600px] xl:w-[1000px]"
              autoPlay
              loop
              muted
            >
              <source src="/images/video.mp4" type="video/mp4" />
            </video>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default Section2;
